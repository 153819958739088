.cardSelect__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  max-width: 250px;
  margin: 0 auto;

  .card__object {
    margin-bottom: 15px;
  }

  .content {
    color: white !important;
    top: unset !important;
    bottom: 0 !important;
  }
}
