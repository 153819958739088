.buyAmountCoin {
    .content {
        font-family: Gotham-bold;
        font-style: normal;
        font-size: 12px;
        line-height: 11px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        letter-spacing: -0.05em;
    
        color: #032544;
    }
}