.modal__container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .45);
    z-index: 100;

    .main {
        background-size: contain;
        background-repeat: no-repeat;
        * {
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}