/* loaded before verify, cant move to cdn */
.flash {
  position: fixed;
  width: 100%;
  height: calc(100% - 45px);
  z-index: 101;

  .flash__container {
    position: relative;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .main {
      position: absolute;
      width: 100%;
      height: calc(50% + 45px);
      bottom: 45px;
      text-align: center;

      .icon {
        width: 375px;
        height: 130px;
        margin: 0 auto;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .bottom {
        width: 100%;
        position: absolute;
        bottom: 20%;
        text-align: center;

        .button {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 23px;
          text-align: center;

          color: #2a1a0b;
          text-transform: uppercase;
        }
        .text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 18px;
          /* identical to box height */

          text-align: center;

          color: #ffffff;
        }
        .ant-progress {
          width: 70%;
          min-width: 217px;

          .ant-progress-inner {
            background: linear-gradient(90deg, #171616 0%, #484747 100%);
            box-shadow: inset 0px 0px 10px #000000;
            border-radius: 2px;

            .ant-progress-bg {
              background: linear-gradient(
                90deg,
                rgba(253, 243, 101, 0) 0.03%,
                #fdf365 92.19%,
                #fffde4 100%
              );
              border: 0.8px solid #000000;
              box-sizing: border-box;
              border-radius: 2px 0px 0px 2px;
              height: 15px !important;
            }
          }
        }
      }
    }
  }

  .footer {
    position: absolute;
    width: 100%;
    height: 45px;
    bottom: 0;
    background: #080635;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
    background-repeat: no-repeat;
    background-position: center;
  }
}

.desktop,
.mobile {
  .flash {
    &__container {
      .main {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        bottom: unset;
        .icon {
          width: 100%;
          height: 550px;
          margin-bottom: 10%;
          background-position: center;
        }

        .bottom {
          bottom: 15%;

          .ant-progress {
            width: 25%;
            margin-bottom: 15px;
          }

          .text {
            line-height: 35px;
          }
        }
      }
    }
  }
}
