.back-to-top > div{
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.07) 100%);
    border-radius: 2px;
    height: 30px;
    width: 30px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    position: fixed;
    bottom: 30%;
    left: 68%;
}

.mobile {
    .back-to-top > div {
        bottom: 10%;
        left: 90%;
    }
 }

@media only screen and (max-width: 1250px) {
    .back-to-top > div{
        left: 76%;
    }
  }

.car-theme.blue {
    .back-to-top > div{
        background: linear-gradient(8.66deg, #19196D 21.76%, #142F98 97.66%);
        border: 1px solid #56689C;
        border-radius: 5px;
    }
  }
  
  .car-theme.black {
    .back-to-top > div{
        background: linear-gradient(4.76deg, #0e1118 9.83%, #686c74 98.64%);
        border: 1px solid #888888;
        border-radius: 5px;
    }
  }
  
  .car-theme.green {
    .back-to-top > div{
        background: linear-gradient(4.76deg, #012d1e 9.83%, #20dd49 98.64%);
        border: 1px solid #6fdd87;
        border-radius: 5px;
    }
  }
  
  .car-theme.brown {
    .back-to-top > div{
        background: linear-gradient(4.76deg, #211c17 9.83%, #a87660 98.64%);
        border: 1px solid #9a745e;
        border-radius: 5px;
    }
  }
  
  .car-theme.pink {
    .back-to-top > div{
        background: linear-gradient(4.76deg, #551932 9.83%, #cd456e 98.64%);
        border: 1px solid #9e5f67;
        border-radius: 5px;
    }
  }

  .okbet-theme.blue {
    .back-to-top > div{
        background: linear-gradient(8.66deg, #19196D 21.76%, #142F98 97.66%);
        border: 1px solid #56689C;
        border-radius: 5px;
    }
  }
  
  .okbet-theme.black {
    .back-to-top > div{
        background: linear-gradient(4.76deg, #0e1118 9.83%, #686c74 98.64%);
        border: 1px solid #888888;
        border-radius: 5px;
    }
  }
  
  .okbet-theme.green {
    .back-to-top > div{
        background: linear-gradient(4.76deg, #012d1e 9.83%, #20dd49 98.64%);
        border: 1px solid #6fdd87;
        border-radius: 5px;
    }
  }
  
  .okbet-theme.brown {
    .back-to-top > div{
        background: linear-gradient(4.76deg, #211c17 9.83%, #a87660 98.64%);
        border: 1px solid #9a745e;
        border-radius: 5px;
    }
  }
  
  .okbet-theme.pink {
    .back-to-top > div{
        background: linear-gradient(4.76deg, #551932 9.83%, #cd456e 98.64%);
        border: 1px solid #9e5f67;
        border-radius: 5px;
    }
  }