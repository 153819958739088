.header() {
  .common-back-header {
    background: @background-navbar;
    height: @navbar-height;
    box-shadow: @header-box-shadow;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      .use_default_font();
      text-transform: uppercase;

      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      font-variant: small-caps;

      color: #ffffff;
      margin: 0 !important;
    }

    .navbar__icon {
      position: absolute;
      top: 5px;
      left: 0;
      padding: 5px 20px;
      width: 20px;

      img {
        width: 100%;
      }
    }
  }

  .desktop {
    .title-container {
      display: flex;
    }

    h1 {
      line-height: unset;
      font-size: 18px;
      margin-left: 5px !important;
    }

    .header-menu {
      position: absolute;
      right: 0;
      display: flex;
      padding: 5px 20px;

      img {
        margin: 0 10px;
      }

      .navbar__menu {
        cursor: pointer;
      }
    }
  }
}
