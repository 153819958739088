.gameModal__container {
  .ant-modal-content {
    background: transparent;
    color: white;

    .ant-modal-footer {
      position: absolute;
      transform: translate(-50%, 0);
      left: 50%;
      top: 100%;
      border: none !important;
    }
  }
}
