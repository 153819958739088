.borderItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-repeat: no-repeat;
    position: relative;

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    > div:nth-child(2) {
        background-repeat: repeat-x;
    }
}