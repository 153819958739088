.copy-button {
    .ant-popover-inner {
        height: 28px;
    }
    
    .ant-popover-inner-content {
        padding: 3px 8px !important;
        margin-left: 3px;
        color: white;
    }
}

