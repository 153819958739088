.results__container {
  &.help {
    .main {
      .container {
        .item {
          white-space: normal;
          margin-bottom: 10px;
          padding: 10px 15px;

          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 18px;

          color: #ffffff;

          .title {
            font-family: Chakra Petch;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 19px;
            /* identical to box height */

            text-transform: uppercase;

            color: #ffffff;
            -webkit-text-fill-color: #ffffff;
            -webkit-text-stroke: 1px #0074ff;
          }

          .main {
            .italic {
              font-style: italic;
            }

            > div {
              margin: 5px 0;
            }
          }
        }
      }
    }
  }
}

.desktop .results__container {
  background-image: unset !important;
  height: 300%;

  .item {
    white-space: normal;
    margin-bottom: 10px;
    padding: 10px 15px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;

    color: #ffffff;

    .title {
      font-family: Chakra Petch;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 19px;
      /* identical to box height */

      text-transform: uppercase;

      color: #ffffff;
      -webkit-text-fill-color: #ffffff;
      -webkit-text-stroke: 1px #0074ff;
    }

    .content {
      margin-top: 10px;
    }
  }
}
