.placeBet__container {
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;
    
    text-transform: uppercase;

    &.buying {
        color: #1A1668;
    }

    .content {
        width: 70%;
    }

    .bottom {
        display: flex;
        justify-content: space-evenly;

        font-family: Chakra Petch;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height */

        text-align: center;
    }
}