.layout() {
  .layout {
    position: relative;
  }

  .layout__header {
    box-shadow: 0px 4px 4px rgba(27, 44, 55, 0.5);
    background: @background-navbar;
    padding: 0;
    height: @navbar-height;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1001;
  }

  .layout__footer {
    background: @background-navbar;
    padding: 0;
    height: @navbar-height;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1001;
  }

  .layout__content {
    &-without-header-footer {
      background: @background-layout-main;
      background-size: cover;
      position: relative;
      width: 100%;
      height: calc(@viewportHeight + 0.1rem);
    }
    &-with-header-footer {
      background: @background-layout-main;
      background-size: cover;
      position: relative;
      top: @navbar-height;
      width: 100%;
      height: calc(@viewportHeight - 2 * @navbar-height + 0.1rem);
    }
    &-with-header-only {
      background: @background-layout-main;
      background-size: cover;
      position: relative;
      top: @navbar-height;
      width: 100%;
      height: calc(@viewportHeight - @navbar-height);
    }
    &-with-footer-only {
      background: @background-layout-main;
      background-size: cover;
      position: relative;
      width: 100%;
      height: calc(@viewportHeight - @navbar-height);
    }
  }
}
