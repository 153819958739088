.buyAmount__container {
    position: relative;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .buy-amount {
        font-family: Gotham;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 24px;
        /* identical to box height */
    
        text-align: center;
    
        color: #FFFFFF;
    
        .label {
            position: absolute;
            top: 0;
            
            font-family: Gotham;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            /* identical to box height */
    
            text-align: center;
    
            color: #000;
            white-space: nowrap;
        }
    }
}