.pagination__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: Chakra Petch;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
    -webkit-text-fill-color: #FFFFFF;
    -webkit-text-stroke: 1.5px #365A86;

    .prev, .next {
        cursor: pointer;
        opacity: 0.5;

        &.active {
            opacity: 1;
        }
    }
}