.results__container {

  .detail-title {
    text-transform: capitalize;
  }

  .transaction {
    &__suit-container {
      width: 100%;
    }
    &__background {
      background: linear-gradient(
          0deg,
          rgba(86, 104, 156, 0.5),
          rgba(86, 104, 156, 0.5)
        ),
        #111b39;
      border: 1px solid #56689c;
      box-sizing: border-box;
      border-radius: 2px;
      width: 16%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__background.active {
      border: 2px solid rgb(248, 218, 45) !important;
    }

    &__win-text {
      position: absolute;
      font-family: Chakra Petch;
      font-weight: bold;
      font-size: 16px;
      text-shadow: -2px 0 rgb(0, 116, 255), 0 2px rgb(0, 116, 255),
        2px 0 rgb(0, 116, 255), 0 -2px rgb(0, 116, 255);
      text-transform: uppercase;
    }

    &__win-text.id-ID {
      font-size: 10px;
    }

    &__win-text.vi-VN {
      font-size: 8px;
    }

    &__black {
      background: black;
    }

    &__red {
      background: #e90000;
    }

    &__payout-container {
      background: rgba(0, 0, 0, 0.4);
      border-radius: 2px;
      height: 20px;
      margin-top: 5px;
    }

    &__payout-info {
      width: 16%;
      text-align: center;
      color: #42ff00;
      font-family: Roboto;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 11px;
    }

    &__payout-info-negative {
      color: red;
    }
  }

  &.transactions {
    .main {
      min-height: 80%;
      .container {
        .item {
          margin-bottom: 10px;
          padding: 18px 10px;
          background-repeat: no-repeat;
          background-size: 100% 100%;

          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          /* identical to box height */

          text-transform: uppercase;

          color: #ffffff;

          .title {
            font-family: Chakra Petch;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 19px;
            display: flex;
            justify-content: space-between;
            /* identical to box height */

            text-transform: uppercase;

            color: #ffffff;
            -webkit-text-fill-color: #ffffff;
            -webkit-text-stroke: 1px #0074ff;
          }

          .rows {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .profit {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 20px;
            /* identical to box height */

            text-align: right;
            text-transform: uppercase;

            .up {
              color: #42ff00;
            }
            .down {
              color: red;
            }
            @media only screen and (min-width: 451px) {
              margin-right: 3%;
            }
          }

          .main {
            margin-top: 10px;

            > div {
              margin: 5px 0;
            }

            > div + div {
              margin: 10px 5px;;
              display: flex;
              justify-content: space-between;
            }

            .hash {
              width: 90px;
            }
          }
        }
      }
    }
  }
}


.car-theme.blue {
  .mobile .results__container.transactions {
    .main {
      .container {
        .item {
              background-color: #111B39;
              border: 2px solid #56689C;
              border-radius: 5px;
          }
        }
      }
  }
}

.car-theme.black {
  .mobile .results__container.transactions {
    .main {
      .container {
        .item {
              background-color: #101318;
              border: 2px solid #CCCCCC;
              border-radius: 5px;
          }
        }
      }
  }
}

.car-theme.green {
  .mobile .results__container.transactions {
    .main {
      .container {
        .item {
              background-color: #012D1E;
              border: 2px solid #6FDD87;
              border-radius: 5px;
          }
        }
      }
  }
}

.car-theme.brown {
  .mobile .results__container.transactions {
    .main {
      .container {
        .item {
              background-color: #1F1313;
              border: 2px solid #FFAE8A;
              border-radius: 5px;
          }
        }
      }
  }
}

.car-theme.pink {
  .mobile .results__container.transactions {
    .main {
      .container {
        .item {
              background-color: #2C0000;
              border: 2px solid #FFA7C1;
              border-radius: 5px;
          }
        }
      }
  }
}



// okbet

.okbet-theme.blue {
  .mobile .results__container.transactions {
    .main {
      .container {
        .item {
              background-color: #111B39;
              border: 2px solid #56689C;
              border-radius: 5px;
          }
        }
      }
  }
}

.okbet-theme.black {
  .mobile .results__container.transactions {
    .main {
      .container {
        .item {
              background-color: #101318;
              border: 2px solid #CCCCCC;
              border-radius: 5px;
          }
        }
      }
  }
}

.okbet-theme.green {
  .mobile .results__container.transactions {
    .main {
      .container {
        .item {
              background-color: #012D1E;
              border: 2px solid #6FDD87;
              border-radius: 5px;
          }
        }
      }
  }
}

.okbet-theme.brown {
  .mobile .results__container.transactions {
    .main {
      .container {
        .item {
              background-color: #1F1313;
              border: 2px solid #FFAE8A;
              border-radius: 5px;
          }
        }
      }
  }
}

.okbet-theme.pink {
  .mobile .results__container.transactions {
    .main {
      .container {
        .item {
              background-color: #2C0000;
              border: 2px solid #FFA7C1;
              border-radius: 5px;
          }
        }
      }
  }
}

.desktop .results__container.transactions {
  margin-right: 0%;
  height: 285%;

  .container-modal {
    min-height: 93%;
  }

  .item {
     .profit {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      /* identical to box height */

      text-transform: uppercase;

      .up {
        color: #42ff00;
      }
      .down {
        color: red;
      }
    }

    .transaction__payout-info {
      font-weight: bold;
      font-size: 13px;
    }

  }

  .container-item, .item:nth-child(1), .bet-details {
    display: flex;
    text-align: left;

    > div + div {
      margin-left: 2%;
    }

    > div:nth-child(1) {
      width: 15%;
    }

    > div:nth-child(2) {
      width: 65%;
    }

    > div:nth-child(3) {
      width: 15%;
    }

    > div:nth-child(4) {
      width: 5%;
    }

    > div:nth-child(1),
    > div:nth-child(2) {
      overflow: hidden;
      word-break: break-all;
    }
  }
  
  .bet-details {
    margin-top:5px;

    >div + div {
      margin-left: 1%;
    }
  }

  .item:nth-child(1) {
    white-space: normal;
    padding: 0 15px;
    background-image: none;

    font-family: Chakra Petch;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    /* identical to box height */
    text-transform: uppercase;

    color: #ffffff;
    -webkit-text-fill-color: #ffffff;
    -webkit-text-stroke: 0.1px #365a86;
  }

  .item + .item {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  }
}

@media only screen and (max-width: 1250px) {
  .desktop .results__container.transactions {
    .item {
      font-size: 13px;
    }

    .item:nth-child(1) {
      font-size: 15px;
    }

    .transaction__payout-info {
      font-size: 12px;
    }
  }
}



@media only screen and (max-width: 1000px) {
  .desktop .results__container.transactions {
    .item {
      font-size: 13px;
      .transaction__payout-info {
        font-size: 10px;
      }

      .profit {
        font-size: 16px;
      }
    }

    .item:nth-child(1) {
      font-size: 14px;
    }
  }
}
