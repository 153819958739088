.results__container {
  padding: 10px;
  height: 100%;
  overflow-y: auto;
  background-repeat: no-repeat;
  background-size: cover;

  .head {
    padding: 20px 2px;
    font-family: Chakra Petch;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    /* identical to box height */

    text-transform: uppercase;

    color: #ffffff;
    -webkit-text-fill-color: #ffffff;
    -webkit-text-stroke: 1.5px #365a86;
  }

  .main {
    .container {
      .item {
        white-space: nowrap;
      }
    }
  }

  .bottom {
    width: 165px;
    margin: 0 auto;
  }
}

.desktop .results__container {
  margin-right: 2%;
  height: 300%;

  .item .content {
    padding-bottom: 10px;
  }
}
