.graphicsInner__container {
  position: relative;
  width: 100%;
  height: 100%;
}

.countdown .ant-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .txt {
    font-family: Flipside BRK;
    font-style: normal;
    font-weight: normal;
    font-size: 75px;
    line-height: 77px;
    /* identical to box height */

    text-align: center;

    color: #080635;
    -webkit-text-fill-color: #080635;
    -webkit-text-stroke: 2px #ff2a2a;
    text-shadow: 0px 0px 15px rgba(255, 42, 42, 0.7);

    &.green {
      -webkit-text-stroke: 2px #00ff18;
      text-shadow: 0px 0px 15px rgba(0, 255, 24, 0.7);
    }
  }
}

.countdown .ant-modal-body {
  .txt.desktop {
    font-size: 100px;
    line-height: 95px;
  }
}
