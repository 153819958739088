.placeBet__container-with-total {
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #0b1016;

  text-transform: uppercase;

  &.buying {
    color: black;

    .content {
      top: 9px;
    }
  }

  &.total-buy {
    .content {
      top: 9px;
    }
  }

  .content {
    top: 12px;
    width: 70%;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.7);
  }

  .bottom {
    display: flex;
    justify-content: space-evenly;

    font-family: Chakra Petch;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    text-align: center;
  }

  .buy-with-total {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buy-with-total.id-ID,
  .betting-btn.th-TH {
    font-size: 18px;
  }

  .buy-with-total.th-TH {
    font-size: 14px;
  }

  .min-max__bet-info {
    font-size: 11px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -17px;
  }

  .bc-2 {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.desktop .placeBet__container-with-total {
  .content {
    width: 100%;
  }

  &.buying {
    .content {
      top: 7px;
    }
  }

  &.total-buy {
    .content {
      top: 7px;
    }
  }

  @media screen and (max-width: 1250px) {
    &.total-buy {
      .content {
        top: 12px;
      }
    }
  }

  .min-max__bet-info {
    position: relative;
    bottom: 8px;
    font-size: 12px;
  }
}

.desktop .placeBet__container-with-total.hi-IN,
.desktop .placeBet__container-with-total.id-ID,
.desktop .placeBet__container-with-total.ko-KR,
.desktop .placeBet__container-with-total.th-TH,
.desktop .placeBet__container-with-total.vi-VN {
  .content {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1250px) {
  // .desktop .placeBet__container-with-total.hi-IN,
  // .desktop .placeBet__container-with-total.id-ID,
  // .desktop .placeBet__container-with-total.ko-KR,
  // .desktop .placeBet__container-with-total.th-TH,
  // .desktop .placeBet__container-with-total.vi-VN {
  //   .buy-with-total {
  //     font-size: 13px;
  //     img {
  //       height: 25px !important;
  //       width: 25px !important;
  //     }
  //   }
  // }

  .desktop .placeBet__container-with-total {
    font-size: 15px;
    .buy-with-total {
      font-size: 13px;
      img {
        height: 25px !important;
        width: 25px !important;
      }
    }

    .min-max__bet-info {
      font-size: 10px;
    }
  }
}
