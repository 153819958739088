.error() {
    .error-redirect-statuses__container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #0E0E3D;
        z-index: 102;
    
        > div {
            margin: 0 0 20px 0;
        }
    
        .normal__text {
            font-family: Gotham;
            font-style: normal;
            font-size: 15px;
            line-height: 14px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
            
            color: #FFFFFF;
            margin: 5px 0;
        }
    
        .big__text {
            font-family: Gotham;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 19px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
    
            color: #FFFFFF;
            margin: 5px 0;
    
        }
    }
}