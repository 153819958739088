.cardObj__container {
    padding: 2px;
    border-radius: 4px;

    .container {
        position: relative;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        
        .card__object {
            font-family: Gotham;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 11px;
        
            &.red {
                color: #A93228;
            }
            &.blue {
                color: #0D304C;
            }
        
            .content {
                position: absolute;
                top: 0;
                left: 0;
                padding: 2px;
            }
        }
    }

    &.active {
        border: 1px solid #00FFE6;
    }
}