.results__container.saba-result {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto !important;

  .game-result {
    height: 70%;
    width: 100%;
    border-radius: 5px;
    background-color: #142f98;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: 98% 98%;
    background-position: center;

    &__content {
      margin: 15px;
    }

    &__id {
      font-family: Chakra Petch;
      font-style: normal;
      font-weight: bold;
      line-height: 50px;
      /* identical to box height */

      text-transform: uppercase;

      color: #ffffff;
      -webkit-text-fill-color: #ffffff;
      -webkit-text-stroke: 1px #0074ff;
      text-align: center;
    }

    &__result {
      margin: 40px 0;
      font-family: Chakra Petch;
      font-style: normal;
      font-weight: bold;
      text-align: center;

      .img-icon.car img{
        width: 35%;
      }
    }

    &__win-text {
      color: #ffffff;
      font-size: 20px;
    }

    &__win-suit,
    &__win-color {
      color: #7f8d9f;
      font-size: 22px;
    }

    .divider {
      background-color: #7f8d9f;
      margin: auto;
      min-width: 90%;
      width: 90%;
    }

    &__datetime {
      margin: 20px 25px;
      font-family: Roboto;
      color: #ffffff;
      font-size: 17px;
    }

    &__date-ago {
      font-family: Roboto;
      font-style: italic;
      color: #7f8d9f;
      font-size: 12px;
    }
  }
}

@media only screen and (max-height: 720px) {
  .results__container.saba-result {
    .game-result {
      &__id {
        line-height: 30px;
      }
 
      &__result {
        margin: 20px 0;

        .img-icon.car img{
          width: 25%;
        }
      }
    }
  }
}

@media only screen and (max-height: 545px) {
  .results__container.saba-result {
    .game-result {
      height: 100%;
 
      &__result {
        margin: 0;

        .img-icon.car img{
          width: 20%;
        }
      }
    }
  }
}

@media only screen and (max-height: 360px) {
  .results__container.saba-result {
    .game-result {

      &__id {
        line-height: 10px;
      }

      &__result {
        .img-icon.car img{
          width: 15%;
        }
      }

      &__win-text {
        font-size: 15px;
      }

      &__win-suit,
      &__win-color {
        font-size: 17px;
      }

      &__datetime {
        margin: 10px 25px;
        font-size: 12px;
      }
    }
  }
}