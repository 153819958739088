@controlsMain__container-padding: 6px;

.okbet-theme {
  .defineLayout(@background-car-color);
  .defineHeaderFooter(@bar-default-color, @bar-header-default-shadow, @bar-footer-default-shadow);

  .header-logo img {
    width: 66px;
    height: auto;
  }

  .footer-logo img{
    width: 100px;
  }

  .commonProperties();

  .error-redirect-statuses__container {
    .icon-error {
      font-family: Chakra Petch;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      /* identical to box height */

      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;

      color: #ff0000;

      .main {
        padding-top: 50px;
      }
    }

    .normal__text {
      font-family: Chakra Petch;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;

      color: #ffffff;
    }

    .big__text {
      font-family: Chakra Petch;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;

      color: #ffffff;
    }
  }

  .cardObj__container {
    padding: 0;
    &.active {
      border: none;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    &.white .container {
      background: #f7f7f7 !important;
    }
    .container {
      background-color: #f7f7f7;

      .card__object {
        .content {
          font-family: Chakra Petch;
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height */

          text-align: center;

          color: #e35644;
        }
        &.DIAMOND .content {
          color: #e35644;
        }
        &.CLUB .content {
          color: #e35644;
        }
        &.HEART .content {
          color: #e35644;
        }
        &.SPADE .content {
          color: #e35644;
        }
      }
    }
  }

  .desktop .cardObj__container {
    .container {
      .card__object {
        .content {
          font-size: 25px;
          line-height: 25px;
        }
      }
    }
  }

  .input-add-minus {
    box-sizing: border-box;
    border-radius: 19px;

    .minus,
    .add {
      border-radius: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      width: 32px;
      height: 32px;

      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 26px;
      text-align: center;

      color: #ffffff;
    }
    .minus:before {
      line-height: 32px;
      content: "-";
    }
    .add:before {
      line-height: 32px;
      content: "+";
    }

    input[type="text"] {
      width: calc(100% - 32px * 2);
    }
  }

  .gameSetting__container {
    z-index: 1001; // on top on wining & count down layer which is z-index 1000
    visibility: hidden;
    &.active {
      visibility: visible;
    }
    .main {
      width: 290.5px;
      height: 487px;
      display: flex;
      flex-direction: column;
      padding: 0 25px;

      .buttons__container {
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto;
        width: 60%;
        margin-bottom: 15%;

        img {
          width: 60px;
          height: 60px;
        }
      }

      .head {
        margin: 10px auto;
        width: 209.25px;
        height: 49.5px;
        background-size: 209.25px 49.5px;

        font-family: Chakra Petch;
        font-style: italic;
        font-weight: bold;
        font-size: 30px;
        /* identical to box height */

        text-align: center;
        text-transform: uppercase;

        color: #fac651;
        -webkit-text-fill-color: #fac651;
        -webkit-text-stroke: 1px #922ab7;
      }

      .section.anonymous .colors {
        opacity: 0.3;
        cursor: not-allowed;
      }

      .section {
        margin-top: 15px;
        > div {
          margin: 8px auto;
        }

        .row {
          padding: 0 10px;
          display: flex;
          justify-content: space-between;

          &.colors {
            > div {
              position: relative;
              width: 43px;
              height: 45px;
            }
            .active::after {
              content: " ";
              background-repeat: no-repeat;
              background-size: contain;
              width: 15px;
              height: 15px;
              position: absolute;
              right: 0;
              bottom: 0;
              z-index: 101;
            }
          }
        }

        .title {
          font-family: Chakra Petch;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 23px;
          display: flex;
          align-items: center;
          text-transform: uppercase;

          color: #ffffff;
          -webkit-text-fill-color: #ffffff;
          -webkit-text-stroke: 1px #922ab7;
        }

        .txt {
          font-family: Chakra Petch;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 23px;
          display: flex;
          align-items: center;
          text-transform: uppercase;

          color: #ffffff;
        }
      }
    }
  }

  .desktop {
    .gameSetting__container {
      .main {
        .head {
          background-size: cover;
          font-size: 50px;
          width: 300px;
          height: auto;
          position: relative;
          bottom: 15px;
        }

        background-size: 100% 100%;
        height: 80%;
        width: 35%;
        min-width: 510px;

        .section {
          margin: 0 5%;
          padding-bottom: 20px;

          .title {
            font-size: 25px;
            margin-bottom: 15px;
          }

          .row {
            justify-content: start;

            .txt {
              font-size: 25px;
            }
            div {
              margin-right: 4%;
            }
          }

          .row.colors > div {
            width: 66px;
            height: 66px;

            img {
              width: 66px;
              height: 66px;
            }
          }

          .row.colors .active::after {
            width: 25px;
            height: 25px;
            bottom: -8px;
            right: -8px;
          }
        }

        .section + .section {
          margin-top: 20px;
        }
      }

      .buttons__container {
        margin-top: 0;
        margin-bottom: 15px;
        height: 100%;
        align-items: center;

        .img-icon {
          width: 4vw;
          height: auto;
        }

        img {
          width: 100%;
          height: 100%;
        }

        img:active {
          width: 98%;
          height: 98%;
        }

        img:hover {
          border-radius: 40px;
          background: rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  .gameSetting__container.setting-modal {
    .setting__container {
      height: 300%;
    }
  }

  .gameSetting__container.history-modal {
    .main {
      width: 90%;
      height: 90%;

      .head {
        width: auto;
        padding: 0 30px;
      }

      .buttons__container {
        margin-bottom: 20px;
        .img-icon {
          width: 4.5vw;
        }
      }
    }
  }

  .gameSetting__container.recent-modal {
    .main {
      width: 45%;
    }

    .buttons__container {
      .refresh-button__container img {
        border: 2px solid rgba(255, 255, 255, 0.5);
        border-radius: 4vw;
        cursor: pointer;
        padding: 10px;
      }

      .refresh-button__container img:hover {
        background: rgba(0, 0, 0, 0.3);
        img {
          background: unset;
        }
      }
    }

    @media only screen and (max-width: 1250px) {
      .main {
        width: 70%;
      }
    }
  }

  .gameMessage__container {
    &.WINNING {
      text-transform: uppercase;
      .ant-modal-content {
        transform: translate(0, -50%);
        top: 50%;
        height: 492px;
        .ant-modal-body {
          height: 492px;
          padding: 0 !important;
        }
      }

      &.lose,
      &.default {
        .ant-modal-content {
          transform: translate(0, -50%);
          top: 50%;
          height: 450px;
          .ant-modal-body {
            height: 450px;
          }
        }
        .car {
          margin-top: -305px;
        }
      }
      &.win {
        .main {
          .bottom {
            margin-top: -20px;
          }
        }
      }

      .main {
        width: 295px;
        background-size: 100%;
        background-position: center;
        padding: 0 0 25px 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-repeat: no-repeat;

        .ribbon {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          mix-blend-mode: screen;
        }

        .top {
          display: flex;
          align-items: center;
          margin-top: 10px;
          .status {
            font-family: Chakra Petch;
            font-style: italic;
            font-weight: bold;
            font-size: 30px;
            line-height: 39px;
            text-align: center;
            text-transform: uppercase;
            margin: 0 -15px;

            text-align: center;

            color: #feb849;
          }
        }
        .card {
          .rotating,
          .flickering {
            position: absolute;
          }
          .rotating {
            top: 0;
            left: calc(((460px - 295px) / 2) - (460px - 295px));
          }
          .flickering {
            left: calc(((220px - 295px) / 2) - (220px - 295px));
          }
          .text {
            font-family: Chakra Petch;
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 36px;
            text-align: center;

            color: #feb849;
          }
        }
        .bottom {
          display: none;
          &.active {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          .profit {
            font-family: Chakra Petch;
            font-style: normal;
            font-weight: bold;
            font-size: 25px;
            line-height: 32px;
            /* identical to box height */

            text-align: center;

            color: #00ff55;

            text-shadow: 0px 0px 7px rgba(0, 255, 85, 0.7);
          }
        }
      }
    }

    &.desktop.WINNING {
      max-height: 780px;

      .ant-modal-content {
        height: 100%;
        top: 52%;
        .ant-modal-body {
          height: 100%;
        }
      }

      .main {
        width: 100%;
        background-size: 100% 100%;
        justify-content: space-around;

        .top {
          position: relative;
          justify-content: space-around;
          .status {
            font-size: 50px;
            text-shadow: 2px 0 2px #8a2be2, -2px 0 2px #8a2be2,
              0 2px 2px #8a2be2, 0 -2px 2px #8a2be2;
          }
        }

        .img-icon.rotating,
        .img-icon.flickering.star {
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }

        .img-icon.flickering.star {
          img {
            height: 100% !important;
            width: 100% !important;
          }
        }

        .img-icon.rotating {
          height: 700px !important;
          width: 700px !important;
          left: -21%;
          opacity: 0.5;
          img {
            height: 100% !important;
            width: 100% !important;
          }
        }

        .img-icon.car {
          img {
            width: 60% !important;
          }
          position: relative;
        }

        @media only screen and (max-height: 800px) {
          .img-icon.car {
            img {
              width: 50% !important;
            }
          }

          .img-icon.rotating {
            height: 650px !important;
            width: 650px !important;
            left: -15%;
            top: 2%;
          }
        }

        @media only screen and (max-width: 1000px) and (max-height: 650px) {
          .img-icon.car {
            img {
              width: 35% !important;
            }
          }

          .img-icon.rotating {
            height: 650px !important;
            width: 650px !important;
            left: -16%;
            top: 0;
          }
        }

        @media only screen and (max-width: 1200px) and (max-height: 750px) {
          .img-icon.rotating {
            top: -10%;
          }
        }

        .text {
          font-size: 36px;
        }

        .bottom,
        .bottom .profit {
          font-size: 30px;
        }
      }

      @media only screen and (max-height: 800px) {
        &.default .img-icon.car,
        &.lose .img-icon.car {
          margin-top: -230px;
        }
      }

      &.default{
        .top {
          top: -15%;
          width: 75%;
        }
      }

      &.win{
        .top {
          width: 75%;
        }
      }

      &.lose {
        .top {
          top: -15%;
          width: 85%;
        }
      }
    }

    h1 {
      font-family: Chakra Petch;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      text-transform: uppercase;

      color: #ffdd00;
    }

    .content {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      text-transform: uppercase;

      color: #ffffff;
    }

    &.desktop {
      h1,
      .content {
        font-size: 28px;
      }

      .content {
        line-height: 32px;
      }
    }
    &.INSUFFICIENT_BALANCE {
      .ant-modal-body {
        padding: 24px 24px 90px 24px;
        height: 487px;
      }
      .ant-modal-footer {
        position: absolute;
        transform: translate(-50%, 0);
        left: 50%;
        top: 80%;
        border: none !important;
      }

      height: 487px;

      .img {
        width: 135px;
        height: 120px;

        .top-up {
          margin-top: -14px;
          margin-left: -7px;
          width: 170px;
          height: 45px;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          font-family: Chakra Petch;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 26px;
          /* identical to box height */

          text-align: center;
          text-transform: uppercase;

          color: #ffdd00;
        }
      }
    }

    &.ACCOUNT_LOCKED {
      .ant-modal-body {
        padding: 24px 24px 90px 24px;
        height: 487px;
      }
      .ant-modal-footer {
        position: absolute;
        transform: translate(-50%, 0);
        left: 50%;
        top: 80%;
        border: none !important;
      }

      height: 487px;

      .img {
        width: 201.2px;
        height: 201.2px;

        .access-denied {
          width: 182px;
          height: 35.5px;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          font-family: Chakra Petch;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          /* identical to box height */

          text-align: center;
          text-transform: uppercase;

          color: #ffffff;
        }
      }
    }

    &.desktop.INSUFFICIENT_BALANCE,
    &.desktop.BUY_ACTION_ERROR,
    &.desktop.SERVER_MAINTENANCE_REMINDER {
      background-size: 100% 100%;

      height: 60%;

      .ant-modal-body,
      .ant-modal-content {
        height: 100%;
      }

      .img {
        width: 35%;
        height: 50%;

        .top-up {
          width: 200px;
          height: 50px;
          margin-top: -100px;
          font-size: 25px;
        }
      }
    }

    &.desktop.ACCOUNT_LOCKED {
      background-size: 100% 100%;

      height: 60%;

      .ant-modal-body,
      .ant-modal-content {
        height: 100%;
      }

      .img {
        width: 40%;
        height: 50%;
        background-position: center;

        .access-denied {
          width: 300px;
          font-size: 25px;
        }
      }
    }

    &.SERVER_MAINTENANCE_REMINDER {
      .ant-modal-body {
        padding: 24px 24px 90px 24px;
        height: 487px;
      }
      .ant-modal-footer {
        position: absolute;
        transform: translate(-50%, 0);
        left: 50%;
        top: 80%;
        border: none !important;
      }

      height: 487px;
    }

    &.BUY_ACTION_ERROR {
      .ant-modal-body {
        padding: 24px 24px 90px 24px;
        height: 487px;
      }
      .ant-modal-footer {
        position: absolute;
        transform: translate(-50%, 0);
        left: 50%;
        top: 80%;
        border: none !important;
      }

      height: 487px;

      .img {
        width: 135px;
        height: 120px;

        .top-up {
          margin-top: -14px;
          margin-left: -7px;
          width: 170px;
          height: 45px;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          font-family: Chakra Petch;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 26px;
          /* identical to box height */

          text-align: center;
          text-transform: uppercase;

          color: #ffdd00;
        }
      }
    }
  }

  .game__container {
    background: @background-car-color;
  }

  .graphicsTop__container {
    height: 47px;

    .notice-fun-play {
      top: 50px;
      width: 150px;
      height: 25px;

      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 5px;

      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;

      color: #ffffff;
    }

    .bar-top {
      .main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
      }
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 13px;
      text-align: center;

      color: #ffffff;

      .title {
        font-family: Chakra Petch;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        text-align: center;

        color: #ffffff;
        text-transform: uppercase;
      }
    }
  }

  .graphicsInner__container {
    top: 47px;
    height: calc(100% - 104px);

    .graphicsInner__main {
      position: fixed;
      width: 100%;
      height: calc(100% - 103px - 92px);
      bottom: 245px;
      transition: bottom 0.5s;
      overflow: hidden;

      .canvas {
        position: absolute;
        width: 150%;
        left: -10%;
        top: -40%;

        canvas,
        img {
          width: 100%;
          image-rendering: optimizeQuality;
          image-rendering: -moz-crisp-edges;
          image-rendering: -webkit-optimize-contrast;
          image-rendering: optimize-contrast;
          -ms-interpolation-mode: nearest-neighbor;
        }
      }

      &.active {
        bottom: 103px;
        transition: bottom 0.5s;

        .canvas {
          width: 100%;
          left: 0;
          top: 0;
          transition: width 1.5s, left 1.5s, top 1.5s;
        }
      }
    }
  }

  .desktop .graphicsInner__container {
    top: 0;
    height: calc(100% - 57px);

    .graphicsInner__main {
      height: 100%;
      bottom: 0;
      top: 45px;

      &.active {
        .canvas {
          width: 100%;
          top: 0;
          bottom: 0;
        }
      }
    }
  }

  .graphicsBottom__container {
    .controlsMain__container {
      display: block;
      height: 200px;
      bottom: -90px;
      transition: bottom 0.5s;

      &.active {
        bottom: 45px;
        transition: bottom 0.5s;
      }

      .controls__container {
        padding: 0;

        .controlsTop__container {
          .aboveTop__container {
            position: absolute;
            width: 100%;
            height: 74px;
            top: -79px;
            padding: @controlsMain__container-padding;
            display: flex;
            justify-content: space-between;

            .choose-suit {
              opacity: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: calc(100% - 90px - @controlsMain__container-padding * 2);
              font-family: Chakra Petch;
              font-style: normal;
              font-weight: bold;
              font-size: 15px;
              line-height: 19px;
              /* identical to box height */

              text-align: center;
              text-transform: uppercase;

              color: #ffffff;

              &.active {
                opacity: 1;
              }

              .progress {
                .ant-progress {
                  width: 60%;
                  min-width: 200px;

                  .ant-progress-bg {
                    background: linear-gradient(
                      90deg,
                      #1170d9 0%,
                      #01f6e5 100%
                    ) !important;
                    width: 0%;
                    height: 6px;
                    transition-timing-function: linear;
                    &.active {
                      width: 100%;
                    }
                  }
                }
              }
            }

            .card-display {
              width: 103px;
              height: 70px;
              padding: 6px;
              background-repeat: no-repeat;
              background-size: contain;

              display: flex;
              justify-content: space-between;

              .card-back {
                .number-bg {
                  width: 30px;
                  height: 30px;
                  background: rgba(0, 0, 0, 0.6);
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  font-family: Chakra Petch;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 13px;
                  line-height: 16px;
                  /* identical to box height */

                  color: #ffffff;
                }
              }

              .card-front {
              }

              // .cardObj__container {
              //   padding: 0 !important;
              // }
            }
          }
          height: 100%;
          background: none;
          .main {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-evenly;
            padding: @controlsMain__container-padding
              @controlsMain__container-padding 10px
              @controlsMain__container-padding;

            > div {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: calc(50% - 5px);
            }

            .currency__label {
              display: none !important;
              width: 100%;
              height: 17px;
              border-radius: 4px;

              font-family: Roboto;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 14px;
              display: flex;
              align-items: center;
              justify-content: center;

              color: #ffffff;

              &.active {
                display: flex !important;
              }
            }

            .buyAmountCoin__container {
              > div {
                display: flex;
                justify-content: space-between;
                margin: 8px 0 0 0;
              }

              .buyAmountCoin {
                position: relative;
                width: calc(50% - 4px);

                .content {
                  font-family: Roboto;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 12px;
                  line-height: 14px;
                  display: flex;
                  align-items: center;
                  text-align: center;

                  color: #ffffff;

                  text-shadow: 0px 0px 6px #fff;

                  text-transform: uppercase;
                }
              }
            }

            .borderItem {
              &::before,
              &::after {
                content: " ";
                background-repeat: no-repeat;
                background-size: contain;
                width: 12px;
                height: 12px;
                position: absolute;
                top: 12px;
              }
              &.buy-amount {
                &::before {
                  left: 10px;
                }
                &::after {
                  right: 10px;
                }
                .title {
                  -webkit-text-fill-color: #ffffff;
                  -webkit-text-stroke: 0.3px #365a86;
                }
              }
              &.suit-choice {
                text-transform: uppercase;
                &::before {
                  left: 10px;
                }
                &::after {
                  right: 10px;
                }
                .title {
                  -webkit-text-fill-color: #ffffff;
                  -webkit-text-stroke: 0.3px #922ab7;
                }

                .top {
                  width: 65%;
                  @media only screen and (min-width: 451px) {
                    width: 50%;
                  }
                  .buy-amount-suit {
                    position: absolute;
                    transform: rotate(-15.43deg);
                  }
                }
              }
              .main {
                display: flex;
                flex-direction: column;
                .top {
                  margin: 0 auto;
                  height: 25px;
                  font-family: Roboto;
                  font-style: normal;
                  font-weight: bold;
                  font-size: 21px;
                  line-height: 21px;
                  text-align: center;

                  color: #ffffff;
                }
                .title {
                  font-family: Chakra Petch;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 16px;
                  /* identical to box height */

                  text-align: center;

                  color: #ffffff;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }
    }
  }

  .desktop .graphicsBottom__container {
    .controlsMain__container {
      height: 90px;
      transition: height 0.5s;

      .main {
        > div {
          margin: 10px;
        }
      }

      .controls__container {
        border-radius: 10px;

        .controlsTop__container {
          border: 2px solid #ffffff;
          border-radius: 7px;

          .aboveTop__container {
            left: -25%;
            top: -12px;
            width: 190px;

            .card-display {
              background-size: 100% 100%;
              height: 130px;
              width: 100%;
              align-items: center;
              padding: 10px;

              .card-back {
                .number-bg {
                  width: 50px;
                  height: 50px;
                  font-size: 25px;
                }
              }
            }
          }

          @media only screen and (max-width: 1250px) {
            .aboveTop__container {
              left: -33.5%;
            }
          }
        }
      }
    }
  }

  .msg-container {
    .ant-message-notice-content {
      background: #000000;
      border-radius: 2px;
      color: #ffffff;
      text-transform: uppercase;
      font-family: Chakra Petch;
      font-size: 18px;
      font-weight: bold;
      padding: 13px 19px;
    }
  }
}

.okbet-theme.blue {
  .cardObj__container {
    .container {
      background: #5166a0;
    }
  }

  .input-add-minus {
    background: #111b39;
    border: 1px solid #56689c;

    .minus,
    .add {
      background: linear-gradient(201.63deg, #5d9eff 7.83%, #7751fd 90.7%);
    }
  }

  .graphicsTop__container {
    background: linear-gradient(8.66deg, #19196d 21.76%, #142f98 97.66%);
  }

  .controlsMain__container {
    .controls__container {
      background: linear-gradient(7.09deg, #131b31 20.86%, #20208a 98.64%);

      .controlsTop__container {
        .main {
          .currency__label {
            background: #5166a0;
          }
        }
      }
    }
  }

  .bet-chips__clear-chip-container {
    background: linear-gradient(
      0deg,
      rgba(86, 104, 156, 0.5),
      rgba(86, 104, 156, 0.5)
    );

    border: 1px solid #56689c;
  }

  .bet-options {
    &__container {
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(0, 0, 0, 0.1) 117.14%
        ),
        #20208a;
      border: 1px solid #5166a0;
    }

    &__background {
      background: linear-gradient(
          0deg,
          rgba(81, 102, 160, 0.5),
          rgba(81, 102, 160, 0.5)
        ),
        #111b39;
      border: 1px solid #5166a0;
    }
  }

  .results__container .item .title {
    -webkit-text-stroke: 1px #365a86;
  }

  .results__container {
    &.results { 
        .result{
          background: rgba(86, 104, 156, 0.5);
          border: 1px solid #56689c;
        }
    }

    .bet-options__suit {
      background: rgba(86, 104, 156, 0.5);
    }

    .result-color {
      .color-container {
        border: 1px solid #56689c;
      }
    }

    .main {
      .container {
        .item {
          .mobile-game-hash, .hash {
            color: #56689c;

            a {
              color: #56689c;
            }
          }

          .hash {
            border-bottom: 1px solid #56689c;
          }
        }
      }
    }
  }

  .drop-down-trigger > div{
    border: 1px solid #56689c;
  }

  .copy-button {
    .ant-popover-arrow {
      border-right-color: #56689c;
      border-bottom-color: #56689c;
    }
    
    .ant-popover-inner {
      background: rgb(81, 102, 160);
      border: 1px solid #56689C;
    }
  }

  .desktop {
    .controlsMain__container {
      .controls__container {
        background: linear-gradient(
          7.09deg,
          rgba(19, 27, 49, 0.7) 20.86%,
          rgba(32, 32, 138, 0.7) 98.64%
        );
        border: 3px solid #0074ff;
        box-sizing: border-box;
        backdrop-filter: blur(15px);
      }
    }
    .graphicsTop__container {
      .desktop__graphic-top-container {
        &__inner-section {
          background: #080635;
          border: 3px solid #0074ff;

          .title {
            -webkit-text-stroke: 1px #365a86;
          }
        }
      }
    }

    .gameSetting__container .main .section .title,
    .results__container .item .title {
      -webkit-text-stroke: 1px #365a86;
      text-shadow: unset;
    }

    .results__container .item {
      .hash, .bet-details {
        color: #56689c;

        a {
          color: #56689c;
        }
      }

      .hash {
        border-bottom: 1px solid #56689c;
      }
    }
  }
}

.okbet-theme.black {
  .cardObj__container {
    .container {
      background: #6c6c6c;
    }
  }

  .input-add-minus {
    background: #101318;
    border: 1px solid #888888;

    .minus,
    .add {
      background: linear-gradient(201.63deg, #aac6d2 7.83%, #303639 90.7%);
    }
  }

  .graphicsTop__container {
    background: linear-gradient(4.76deg, #0e1118 9.83%, #686c74 98.64%);
  }

  .controlsMain__container {
    .controls__container {
      background: linear-gradient(180deg, #686c74 0%, #0e1118 100%);

      .controlsTop__container {
        .main {
          .currency__label {
            background: #6c6c6c;
          }
        }
      }
    }
  }

  .bet-chips__clear-chip-container {
    background: rgba(108, 108, 108, 0.5);
    border: 1px solid #888888;
  }

  .bet-options {
    &__container {
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(0, 0, 0, 0.1) 117.14%
        ),
        #686c74;
      border: 1px solid #CCCCCC;
    }

    &__background {
      background: rgba(108, 108, 108, 0.5);
      border: 1px solid #CCCCCC;
    }
  }

  .results__container.help .main .container .item .title,
  .results__container .head,
  .results__container.transactions .main .container .item .title {
    -webkit-text-stroke: 1px #666666;
  }

  .results__container {
    &.results { 
        .result{
          background: rgba(108, 108, 108, 0.5);
          border: 1px solid #888888;
        }
    }

    .result-color {
      .color-container {
        border: 1px solid #888888;
      }
    }

    .main {
      .container {
        .item {
          .mobile-game-hash, .hash {
            color: #6C6C6C;

            a {
              color: #6C6C6C;
            }
          }

          .hash {
            border-bottom: 1px solid #6C6C6C;
          }
        }
      }
    }

    .bet-options__suit {
      background: rgba(108, 108, 108, 0.5);
    }

    .transaction__background {
      border: 1px solid #CCCCCC;
    }

    .void {
      .transaction__color {
        background: rgba(108, 108, 108, 0.5) !important;
      }
    }
  }

  .drop-down-trigger > div{
    border: 1px solid #888888;
  }

  .copy-button {
    .ant-popover-arrow {
      border-right-color: #888;
      border-bottom-color: #888;
    }
    
    .ant-popover-inner {
      background: rgb(108, 108, 108);
      border: 1px solid #888;
    }
  }

  .desktop {
    .controlsMain__container {
      .controls__container {
        background: linear-gradient(
          180deg,
          rgba(104, 108, 116, 0.7) 0%,
          rgba(14, 17, 24, 0.7) 100%
        );
        border: 3px solid #aac6d2;
        box-sizing: border-box;
        backdrop-filter: blur(15px);
      }
    }
    .graphicsTop__container {
      .desktop__graphic-top-container {
        &__inner-section {
          background: #2f3137;
          border: 3px solid #aac6d2;

          .title {
            -webkit-text-stroke: 1px #666666;
          }
        }
      }
    }

    .gameSetting__container .main .section .title,
    .results__container .item .title {
      -webkit-text-stroke: 1px #666666;
      text-shadow: unset;
    }

    .results__container .item {
          .hash, .bet-details {
            color: #6C6C6C;

            a {
              color: #6C6C6C;
            }
          }

          .hash {
            border-bottom: 1px solid #6C6C6C;
          }
    }
  }
}

.okbet-theme.green {
  .cardObj__container {
    .container {
      background: #51a062;
    }
  }

  .input-add-minus {
    background: #101318;
    border: 1px solid #6fdd87;

    .minus,
    .add {
      background: linear-gradient(201.63deg, #60ff7b 7.83%, #00563a 90.7%);
    }
  }

  .graphicsTop__container {
    background: linear-gradient(4.76deg, #012d1e 9.83%, #20dd49 98.64%);
  }

  .controlsMain__container {
    .controls__container {
      background: linear-gradient(180deg, #20dd49 -24.1%, #012d1e 100%);

      .controlsTop__container {
        .main {
          .currency__label {
            background: #51a062;
          }
        }
      }
    }
  }

  .bet-chips__clear-chip-container {
    background: rgba(81, 160, 98, 0.5);
    border: 1px solid #6fdd87;
  }

  .bet-options {
    &__container {
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(0, 0, 0, 0.1) 117.14%
        ),
        #20dd49;
      border: 1px solid #6fdd87;
    }

    &__background {
      background: rgba(81, 160, 98, 0.5);
      border: 1px solid #6fdd87;
    }
  }

  .results__container.help .main .container .item .title,
  .results__container .head,
  .results__container.transactions .main .container .item .title {
    -webkit-text-stroke: 1px #43ca0d;
  }

  .results__container {
    &.results { 
        .result{
          background: rgba(81, 160, 98, 0.5);
          border: 1px solid #43ca0d;
        }
    }

    .bet-options__suit {
      background: rgba(81, 160, 98, 0.5);
    }

    .transaction__background {
      border: 1px solid #6FDD87;
    }

    .void {
      .transaction__color {
        background: rgba(81, 160, 98, 0.5) !important;
      }
    }

    .result-color {
      .color-container {
        border: 1px solid #43ca0d;
      }
    }

    .main {
      .container {
        .item {
          .mobile-game-hash, .hash {
            color: #51A062;

            a {
              color: #51A062;
            }
          }

          .hash {
            border-bottom: 1px solid #51A062;
          }
        }
      }
    }
  }

  .drop-down-trigger > div{
    border: 1px solid #43ca0d;
  }

  .copy-button {
    .ant-popover-arrow {
      border-right-color: #43ca0d;
      border-bottom-color: #43ca0d;
    }
    
    .ant-popover-inner {
      background: rgb(81, 160, 98);
      border: 1px solid #43ca0d;
    }
  }
  .desktop {
    .controlsMain__container {
      .controls__container {
        background: linear-gradient(
          180.98deg,
          rgba(32, 221, 73, 0.7) -48.1%,
          rgba(1, 45, 30, 0.7) 99.16%
        );
        border: 3px solid #43ca0d;
        box-sizing: border-box;
        backdrop-filter: blur(15px);
      }
    }
    .graphicsTop__container {
      .desktop__graphic-top-container {
        &__inner-section {
          background: #014b32;
          border: 3px solid #43ca0d;

          .title {
            -webkit-text-stroke: 1px #007952;
          }
        }
      }
    }

    .gameSetting__container .main .section .title,
    .results__container .item .title {
      -webkit-text-stroke: 1px #007952;
      text-shadow: unset;
    }

    .results__container .item {
      .hash, .bet-details {
        color: #51A062;

        a {
          color: #51A062;
        }
      }

      .hash {
        border-bottom: 1px solid #51A062;
      }
    }
  }
}

.okbet-theme.brown {
  .cardObj__container {
    .container {
      background: #9a745e;
    }
  }

  .input-add-minus {
    background: #1f1313;
    border: 1px solid #9a745e;

    .minus,
    .add {
      background: linear-gradient(201.63deg, #faa872 7.83%, #784934 90.7%);
    }
  }

  .graphicsTop__container {
    background: linear-gradient(4.76deg, #211c17 9.83%, #a87660 98.64%);
  }

  .controlsMain__container {
    .controls__container {
      background: linear-gradient(180deg, #a87660 -24.1%, #211c17 100%);

      .controlsTop__container {
        .main {
          .currency__label {
            background: #9a745e;
          }
        }
      }
    }
  }

  .bet-chips__clear-chip-container {
    background: rgba(154, 116, 94, 0.5);
    border: 1px solid #FFAE8A;
  }
  .bet-options {
    &__container {
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(0, 0, 0, 0.1) 117.14%
        ),
        #a87660;
      border: 1px solid #FFAE8A;
    }

    &__background {
      background: rgba(154, 116, 94, 0.5);
      border: 1px solid #FFAE8A;
    }
  }

  .results__container.help .main .container .item .title,
  .results__container .head,
  .results__container.transactions .main .container .item .title {
    -webkit-text-stroke: 1px #885c48;
  }

  .results__container {
    &.results { 
        .result{
          background: rgba(154, 116, 94, 0.5);
          border: 1px solid #885c48;
        }
    }

    .bet-options__suit {
      background: rgba(154, 116, 94, 0.5);
    }

    .transaction__background {
      border: 1px solid #FFAE8A;
    }

    .void {
      .transaction__color {
        background: rgba(154, 116, 94, 0.5) !important;
      }
    }

    .result-color {
      .color-container {
        border: 1px solid #885c48;
      }
    }

    .main {
      .container {
        .item {
          .mobile-game-hash, .hash {
            color: #9A745E;

            a {
              color: #9A745E;
            }
          }

          .hash {
            border-bottom: 1px solid #9A745E;
          }
        }
      }
    }
  }

  .drop-down-trigger > div{
    border: 1px solid #885c48;
  }

  .copy-button {
    .ant-popover-arrow {
      border-right-color: #885c48;
      border-bottom-color: #885c48;
    }
    
    .ant-popover-inner {
      background: rgb(154, 116, 94);
      border: 1px solid #885c48;
    }
  }

  .desktop {
    .controlsMain__container {
      .controls__container {
        background: linear-gradient(
          180deg,
          rgba(168, 118, 96, 0.7) -24.1%,
          rgba(33, 28, 23, 0.7) 100%
        );
        border: 3px solid #fac472;
        box-sizing: border-box;
        backdrop-filter: blur(15px);
      }
    }
    .graphicsTop__container {
      .desktop__graphic-top-container {
        &__inner-section {
          background: #3a3128;
          border: 3px solid #fac472;

          .title {
            -webkit-text-stroke: 1px #885c48;
          }
        }
      }
    }

    .gameSetting__container .main .section .title,
    .results__container .item .title {
      -webkit-text-stroke: 1px #885c48;
      text-shadow: unset;
    }

    .results__container .item {
      .hash, .bet-details {
        color: #9A745E;

        a {
          color: #9A745E;
        }
      }

      .hash {
        border-bottom: 1px solid #9A745E;
      }
    }
  }
}

.okbet-theme.pink {
  .cardObj__container {
    .container {
      background: #d2547a;
    }
  }

  .input-add-minus {
    background: #2c0000;
    border: 1px solid #9e5f67;

    .minus,
    .add {
      background: linear-gradient(201.63deg, #ff5dbe 7.83%, #fd5151 90.7%);
    }
  }

  .graphicsTop__container {
    background: linear-gradient(4.76deg, #551932 9.83%, #cd456e 98.64%);
  }

  .controlsMain__container {
    .controls__container {
      background: linear-gradient(4.76deg, #551932 9.83%, #cd456e 98.64%);

      .controlsTop__container {
        .main {
          .currency__label {
            background: #d2547a;
          }
        }
      }
    }
  }

  .bet-chips__clear-chip-container {
    background: linear-gradient(
      0deg,
      rgba(210, 84, 122, 0.5),
      rgba(210, 84, 122, 0.5)
    );
    border: 1px solid #f77f93;
  }

  .bet-options {
    &__container {
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(0, 0, 0, 0.1) 117.14%
        ),
        #cd456e;
      border: 1px solid #FFA7C1;
    }

    &__background {
      background: rgba(210, 84, 122, 0.5);
      border: 1px solid #FFA7C1;
    }
  }

  .results__container.help .main .container .item .title,
  .results__container .head,
  .results__container.transactions .main .container .item .title {
    -webkit-text-stroke: 1px #7e1b38;
  }

  .results__container {
    &.results { 
        .result{
          background: rgba(210, 84, 122, 0.5);
          border: 1px solid #7e1b38;
        }
    }

    .bet-options__suit {
      background: rgba(210, 84, 122, 0.5);
    }

    .transaction__background {
      border: 1px solid #FFA7C1;
    }

    .void {
      .transaction__color {
        background: rgba(210, 84, 122, 0.5) !important;
      }
    }

    .result-color {
      .color-container {
        border: 1px solid #7e1b38;
      }
    }

    .main {
      .container {
        .item {
          .mobile-game-hash, .hash {
            color: #D2547A;

            a {
              color: #D2547A;
            }
          }

          .hash {
            border-bottom: 1px solid #D2547A;
          }
        }
      }
    }
  }
  
  .drop-down-trigger > div{
    border: 1px solid #7e1b38;
  }

  .copy-button {
    .ant-popover-arrow {
      border-right-color: #7e1b38;
      border-bottom-color: #7e1b38;
    }
    
    .ant-popover-inner {
      background: rgb(210, 84, 122);
      border: 1px solid #7e1b38;
    }
  }

  .desktop {
    .controlsMain__container {
      .controls__container {
        background: linear-gradient(
          4.76deg,
          rgba(85, 25, 50, 0.7) 9.83%,
          rgba(205, 69, 110, 0.7) 98.64%
        );
        border: 3px solid #ff7ecc;
        box-sizing: border-box;
        backdrop-filter: blur(15px);
      }
    }

    .graphicsTop__container {
      .desktop__graphic-top-container {
        &__inner-section {
          background: #4a152b;
          border: 3px solid #ff7ecc;

          .title {
            -webkit-text-stroke: 1px #7e1b38;
          }
        }
      }
    }

    .gameSetting__container .main .section .title,
    .results__container .item .title {
      -webkit-text-stroke: 1px #7e1b38;
      text-shadow: unset;
    }

    .results__container .item {
      .hash, .bet-details {
        color: #D2547A;

        a {
          color: #D2547A;
        }
      }

      .hash {
        border-bottom: 1px solid #D2547A;
      }
    }
  }
}
