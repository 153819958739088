.game__container {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}

.gameMessage__container {
  width: 330px;
  background-repeat: no-repeat;
  background-size: contain;
  .ant-modal-content {
    overflow: hidden;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  h1 {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
  }

  .content {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    /* or 133% */

    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
  }

  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 0 auto;
  }

  &.WINNING {
    @media (max-width: 767px) {
      &.ant-modal {
        max-width: 100vw !important;
      }
    }

    &.ant-modal {
      max-width: 100vw !important;
    }
    .ant-modal-content {
      transform: translate(0, 50%);
      top: 50%;
      height: 100%;
      .ant-modal-body {
        height: 100%;
      }
    }

    height: calc(100% - 90px);
    background-size: 100%;
    background-position: center;

    .img {
      width: 274px;
      height: 160px;
    }

    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 14px;
    text-align: center;

    color: #ffffff;
  }

  &.INSUFFICIENT_BALANCE {
    .ant-modal-body {
      height: 330px;
    }

    height: 330px;

    .img {
      width: 135px;
      height: 120px;
    }
  }

  &.ACCOUNT_LOCKED {
    .ant-modal-body {
      height: 400px;
    }

    height: 400px;

    .img {
      width: 73px;
      height: 98px;
    }

    .contact-us {
      font-family: Gotham;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 20px;
      /* identical to box height, or 133% */

      text-align: center;
      text-decoration-line: underline;
      text-transform: uppercase;

      color: #ffffff;
    }
  }
}
