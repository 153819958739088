.graphicsTop__container {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 45px;
  z-index: 99;

  .borderItem {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 40px;
    /* identical to box height */

    color: #ffffff;
  }

  .notice-fun-play {
    position: absolute;
    top: 50px;
    width: 150px;
    height: 25px;

    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    color: #ffffff;
  }
}

.desktop .graphicsTop__container {
  background: transparent !important;
  position: absolute;
  height: auto !important;

  .desktop__graphic-top-container {
    width: 100%;
    margin: 10px 30px;
  }

  .desktop__graphic-top-container {
    &__inner-section {
      background: #080635;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      border: 3px solid #0074ff;
      width: 320px;
      height: 45px;
      color: #ffffff;
      font-family: Roboto;
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;

      .title {
        font-family: Chakra Petch;
        font-weight: bold;
        text-transform: uppercase;
        text-shadow: 2px 0 2px #365a86, -2px 0 2px #365a86, 0 2px 2px #365a86,
          0 -2px 2px #365a86;
      }

      .content {
        font-weight: 550;
      }
    }

    &__outer-section {
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }

  .notice-fun-play {
    top: initial !important;
    font-size: 20px !important;
    width: 210px !important;
    position: initial !important;
    height: 45px !important;
  }
}
