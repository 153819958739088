.results__container {
  &.results {
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .result{ 
      border-radius: 2px;
      width: 33px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .result-color {
      .color-container {
        width: 33px;
        height: 25px;
        margin: auto;
        border-radius: 2px;
      }

      .color-container.red {
        background: #ff0000;
      }

      .color-container.black {
        background: #000000;
      }

      .color-container.voided {
        border: unset;
        background: unset;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .main {
      .container {
        .item {
          .content {
            > div:nth-child(1) {
              width: 30%;
              text-align: left;
            }
            > div:nth-child(2){
              width: 30%;
              text-align: center;
              display: flex;
              justify-content: space-evenly;
              align-items: center;
            }
            > div:nth-child(3) {
              width: 30%;
              text-align: right;
            }
            > div:nth-child(4){
              width: 5%;
              text-align: center;
              margin-left: 15px;
            }
          }

          .content, .mobile-game-hash {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          }

          .mobile-game-hash {
            margin: 5px 0 8px;

            >div:nth-child(1) {
              width: 20%;
              text-transform: capitalize;

            }

            >div:nth-child(2) {
              width: 55%;
              display: flex;
              align-items: center;
            }
          }
          
          margin-bottom: 10px;
          padding: 17px 15px;
          background-repeat: no-repeat;
          background-size: 100% 100%;

          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 15px;

          text-transform: uppercase;

          color: #ffffff;

          &.head {
            white-space: normal;
            padding: 0 15px;
            background-image: none;

            font-family: Chakra Petch;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            text-transform: uppercase;

            color: #ffffff;
            -webkit-text-fill-color: #ffffff;
            -webkit-text-stroke: 0.1px #365a86;
          }
        }
      }
    }
  }

  .mobile-refresh-button-container {
    text-align: center;
    display: flex;
    align-items: center;
  }
  
}

.desktop .results__container {
  &.results {
    margin: 0 2%;
    .head-modal {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .container {
      .item {
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        );

        .result {
          font-weight: bold;
          width: 63px;
          height: 37px;
        }

        .result-color {
          .color-container {
            width: 63px;
            height: 37px;
          }
        }
        
        .content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          > div:nth-child(1) {
            width: 20%;
            text-align: left;
          }
          > div:nth-child(2) {
            width: 19%;
            text-align: left;
            display: flex;

            .result {
              margin-right: 10px;
            }
          }
          > div:nth-child(3) {
            width: 19%;
            text-align: left;
          }
          > div:nth-child(4) {
            width: 25%;
            text-align: left;
            display: flex;
          }
        }

        margin-bottom: 10px;
        padding: 5px 15px;
        background-repeat: no-repeat;
        background-size: 100% 100%;

        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 15px;

        text-transform: uppercase;
        color: #ffffff;
      }

      .head-modal {
        white-space: normal;
        padding: 0 15px;
        background-image: none;

        font-family: Chakra Petch;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 19px;
        /* identical to box height */
        text-transform: uppercase;

        color: #ffffff;
        -webkit-text-fill-color: #ffffff;
        -webkit-text-stroke: 0.1px #365a86;
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .desktop .results__container {
    &.results {
      .container {
        .item {
          font-size: 13px;
          .content > div:nth-child(2) {
            width: 25%;
          }

          .result,
          .result-color > .color-container  {
            width: 55px !important;
          }
        }
      }
    }
  }
}
