.footer() {
  .footer__container {
    display: flex;
    height: @navbar-height;
    background-color: @background-navbar;
    box-shadow: @footer-box-shadow;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .footer-logo {
    margin: auto;
  }

  .footer__item {
    display: flex;
    justify-content: center;
    width: 25%;

    .footer__icon-container {
      width: fit-content;
      margin: auto;
      position: relative;

      .footer__icon {
        opacity: 0.5;

        &.selected {
          opacity: 1;
        }
      }
    }
  }
}
