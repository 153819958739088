.desktop .controlsMain__container {
  .bet-chips__display {
    .img-icon {
      top: unset;
      left: unset;
      right: 0;
      margin-right: 3px;
    }
  }
  .bet-chips__total-amount {
    z-index: 999;
  }
}

.controlsMain__container {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 220px !important;
  z-index: 99;
  display: none;

  .bet-setting-btn {
    width: 110px;
    height: 71px;
    margin-left: 15px;
    text-align: center;
    color: #ffffff;
    font-family: Chakra Petch;
    font-weight: bold;
    position: relative;
    top: -10px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
  }

  .bet-setting-btn.cancel {
    background-image: url(../../../../common/assets/images/themes/car/game/graphics-container/common/rebet-disabled.png);
    cursor: not-allowed;
  }

  .bet-setting-btn.cancel.id-ID,
  .bet-setting-btn.cancel.vi-VN {
    font-size: 10px;
  }

  .bet-panel {
    width: 100% !important;
  }

  .bet-chips__total-amount {
    position: absolute;
    top: -30px;
    right: -3px;
    background: #000000;
    border: 0.5px solid #ffffff;
    box-sizing: border-box;
    border-radius: 2px;
    color: #f8da2d;
    font-size: 11px;
    height: 15px;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bet-chips__display {
    position: absolute;
    width: 100%;

    .img-icon {
      position: absolute;
      left: -2px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #032544;
      font-family: Chakra Petch;
      font-weight: bold;
      font-size: 9px;
      top: -60px;
    }
  }

  .bet-options {
    &__container {
      box-sizing: border-box;
      border-radius: 2px;
      padding: 5px;
    }

    &__suit-container {
      width: 66%;

      .active {
        border: 2px solid rgb(248, 218, 45) !important;
      }

      .disabled {
        opacity: 0.3;
      }
    }

    &__color-container {
      width: 30%;

      .active {
        border: 2px solid rgb(248, 218, 45) !important;
      }

      .disabled {
        opacity: 0.3;
      }
    }

    &__background {
      box-sizing: border-box;
      border-radius: 2px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__suit {
      width: 24%;
      cursor: pointer;
    }

    &__color {
      width: 49%;
      border-radius: 2px;
      cursor: pointer;
    }

    &__red {
      background: #e90000 !important;
    }

    &__black {
      background: #000000 !important;
    }

    &__payout-info {
      border-radius: 2px;
      font-family: Roboto;
      color: white;
      margin-top: 3px;
      padding: 1px 0;
      font-size: 11px;
      background: rgba(0, 0, 0, 0.4);
      font-weight: bold;
    }
  }

  .bet-chips {
    &__container.disabled {
      opacity: 0.3;
    }

    &__chip-container {
      width: 66%;
    }

    &__clear-chip-container {
      width: 30%;
      height: 48px;
      box-sizing: border-box;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Chakra Petch;
      font-weight: bold;
      color: white;
      cursor: pointer;
    }

    &__chip-img-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20%;
      color: #032544;
      font-family: Chakra Petch;
      font-weight: bold;
    }

    &__chip-img-container.active {
      img {
        border-radius: 50%;
        padding: 2px;
        width: 50px !important;
        height: 50px !important;
        box-sizing: border-box;
      }
    }

    &__chip-img-container.active img {
      border: 2px solid #f8da2d;
    }

    &__amount {
      position: absolute;
      cursor: pointer;
    }
  }

  .bet__currency-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-family: Roboto;
    color: white;
    opacity: 0.5;
    font-style: italic;
    font-size: 12px;
  }

  &.active {
    display: block;
  }

  .controls__container {
    width: 100%;
    height: 100%;
    background-size: 100% 280px;
    padding: 30px 0 0 0;

    .close {
      position: absolute;
      top: 0;
      right: 0.9rem;
      padding: 10px 10px 5 5;
    }

    .controlsTop__container {
      position: relative;
      width: 100%;
      height: 100px;
      background-size: 100% 57px;
      display: flex;
      justify-content: center;

      .main {
        margin: 0 auto;
        width: 50%;
        min-width: 188px;
      }

      .ant-progress-bg {
        height: 5px !important;
        background: linear-gradient(90deg, #07192c 0%, #00ffe6 100%);
      }
      .ant-progress-inner {
        background: rgba(255, 255, 255, 0.3);
      }

      .card__title {
        margin: 10px 0 -2px 0;

        font-family: Gotham-bold;
        font-style: normal;
        font-size: 12px;
        line-height: 11px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        color: #ffffff;
      }
    }

    .controlsBottom__controller {
      display: flex;
      justify-content: space-between;
      padding: 10px;

      > div:nth-child(1) {
        width: 55%;
      }
      > div:nth-child(2) {
        width: 45%;
      }

      .currency__label {
        font-family: Gotham;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 11px;
        text-align: center;

        color: #ffffff;

        margin: 5px 0;
      }

      .buyAmountCoin__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .placeBet__container {
        font-family: Gotham;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 11px;
        text-align: center;

        color: #ffffff;
      }
    }
  }
}

.desktop .controlsMain__container {
  width: 65%;
  max-width: 960px;
  height: 130px !important;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 45px !important;

  &.active {
    height: 250px !important;
    transition: bottom 0.5s;
  }

  .bet-options__payout-info {
    font-size: 18px;
  }

  .bet-setting-btn {
    position: initial;
    margin-left: 0;
    width: auto;
  }

  .bet-chips {
    &__clear-chip-container {
      height: 58px;
      text-align: center;
    }
  }

  @media only screen and (min-width: 1250px) {
    .bet-chips__chip-img-container img {
      width: 60px !important;
      height: 60px !important;
    }

    .bet-chips__chip-img-container.active {
      img {
        width: 62px !important;
        height: 62px !important;
      }
    }
  }

  @media only screen and (max-width: 1250px) {
    .bet-chips__chip-img-container img {
      width: 40px !important;
      height: 40px !important;
    }

    .bet-chips__chip-img-container.active {
      img {
        width: 50px !important;
        height: 50px !important;
      }
    }
  }

  .countdown-container {
    .choose-suit {
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Chakra Petch;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      /* identical to box height */

      text-align: center;
      text-transform: uppercase;

      color: #ffffff;

      &.active {
        opacity: 1;
      }

      .progress {
        width: 70%;
        margin-left: 6%;

        .ant-progress {
          width: 100%;
          min-width: 200px;

          .ant-progress-bg {
            background: linear-gradient(
              90deg,
              #1170d9 0%,
              #01f6e5 100%
            ) !important;
            width: 0%;
            height: 6px;
            transition-timing-function: linear;
            &.active {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    .countdown-container {
      .choose-suit { 
        font-size: 14px;
      }
    }
  }
  
  .bet__currency-info {
    font-size: 15px;
    bottom: 32px;
  }
}

@media only screen and (min-width: 1000px) {
  .bet-chips__chip-img-container {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1250px) {
  .desktop .controlsMain__container {
    width: 60%;
    max-width: 850px;
  }
}
