@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 20s linear infinite;
  -moz-animation: rotating 20s linear infinite;
  -ms-animation: rotating 20s linear infinite;
  -o-animation: rotating 20s linear infinite;
  animation: rotating 20s linear infinite;
}

.refresh-button__container:active {
  -webkit-animation: rotating 0.3s linear infinite;
  -moz-animation: rotating 0.3s linear infinite;
  -ms-animation: rotating 0.3s linear infinite;
  -o-animation: rotating 0.3s linear infinite;
  animation: rotating 0.3s linear infinite;
}

.refresh.button__container:active {
  .content .img-icon img {
    -webkit-animation: rotating 0.3s linear infinite;
    -moz-animation: rotating 0.3s linear infinite;
    -ms-animation: rotating 0.3s linear infinite;
    -o-animation: rotating 0.3s linear infinite;
    animation: rotating 0.3s linear infinite;
  }
}

// flicker
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.flickering {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
}

// fall
@-webkit-keyframes falling /* Safari and Chrome */ {
  from {
    top: 0px;
    opacity: 1;
  }
  to {
    top: 100px;
    opacity: 0;
  }
}

@keyframes falling {
  from {
    top: 0px;
    opacity: 1;
  }
  to {
    top: 100px;
    opacity: 0;
  }
}

.falling {
  -webkit-animation: falling 2s linear infinite;
  -moz-animation: falling 2s linear infinite;
  -ms-animation: falling 2s linear infinite;
  -o-animation: falling 2s linear infinite;
  animation: falling 2s linear infinite;
}

.falling2 {
  -webkit-animation: falling 2s linear 1s infinite;
  -moz-animation: falling 2s linear 1s infinite;
  -ms-animation: falling 2s linear 1s infinite;
  -o-animation: falling 2s linear 1s infinite;
  animation: falling 2s linear 1s infinite;
}

.falling.mobile {
  -webkit-animation: falling 5s linear infinite;
  -moz-animation: falling 5s linear infinite;
  -ms-animation: falling 5s linear infinite;
  -o-animation: falling 5s linear infinite;
  animation: falling 5s linear infinite;
}

.falling2.mobile {
  -webkit-animation: falling 5s linear 1s infinite;
  -moz-animation: falling 5s linear 1s infinite;
  -ms-animation: falling 5s linear 1s infinite;
  -o-animation: falling 5s linear 1s infinite;
  animation: falling 5s linear 1s infinite;
}

// flip card
.flip-card {
  position: absolute;
  z-index: 1;
  background-color: transparent;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card.active .flip-card-inner {
  transition: transform 0.2s;
  transform: rotateY(180deg) translate(-115%, 0);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  z-index: 1;
}

.flip-card-back {
  top: 0px;
  transform: rotateY(180deg);
}
