.car-input-progress {
    display: flex;
    justify-content: space-between;
    line-height: 0;
    width: calc(100% - 32px * 2 - 8px);
    margin: 0 4px;

    .input__progress-bar {
        &-fill, &-empty {
            margin: 0 -2.5px;
        }
    }
}