.input-add-minus {
    display: flex;
    align-items: center;

    .minus, .add {
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
        width: 42px;
        height: 26px;

        text-align: center;
        font-weight: bolder;
        font-size: 25px;
        opacity: .5;

        &.active {
            opacity: 1;
        }
    }

    .minus {
        line-height: 18px;
    }

    .add {
        line-height: 20px;
    }

    input[type="text"] {
        -webkit-appearance: none;
        padding: 0;
        resize: none;
        border: 0;
        
        font-family: Gotham;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 19px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        
        color: #FFFFFF;

        width: calc(100% - 42px * 2);
        background: transparent;
    }
}