@import "antd/dist/antd.css";
@import "./animating.less";
@import "./card-theme.less";
@import "./car-theme.less";
@import "./okbet-theme.less";
@import "./results__container.less";
@import "./fonts.less";

@import (reference)
  "../../../components/error_redirect_statuses/ErrorHandler.less";
@import (reference) "../../../components/app/layouts/Layout.less";
@import (reference) "../../../components/app/layouts/Header.less";
@import (reference) "../../../components/app/layouts/Footer.less";

@import (reference) "../../../components/game/Game.less";
@import (reference)
  "../../../components/game/GraphicsContainer/TopContainer/GraphicsTopContainer.less";
@import (reference)
  "../../../components/game/GraphicsContainer/GraphicsContainer.less";

@navbar-height: 45px;
@viewportHeight: calc(var(--vh, 1vh) * 100);

@background-default-color: black;
@bar-default-color: #080635;
@bar-header-default-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
@bar-footer-default-shadow: 0px -2px 20px rgba(0, 0, 0, 0.5);

@background-car-color: #080635;
@car-theme-game-top-header-height: 47px;

// ============================================
// FONTS
// ============================================
.use_default_font {
  font-family: @font-default;
}

.use_medium_font {
  font-family: @font-medium;
}

.use_bold_font {
  font-family: @font-bold;
  font-weight: 300;
}

// ============================================
// SCROLLBAR
// ============================================

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.mobile {
  ::-webkit-scrollbar {
    display: none;
  }
}

.desktop {
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
  }
}

// ============================================
// LAYOUT
// =============================================

html,
body,
#root,
.root__wrapper,
.desktop,
.mobile,
.wrapper,
.layout {
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
* {
  box-sizing: border-box;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.hash a:hover{
  color: white !important;
}

.defineLayout(@bg-layout-main) {
  @background-layout-main: @bg-layout-main;
}

.defineHeaderFooter(@bg-navbar, @box-shadow-header, @box-shadow-footer) {
  @background-navbar: @bg-navbar;
  @header-box-shadow: @box-shadow-header;
  @footer-box-shadow: @box-shadow-footer;
}

.commonProperties() {
  .root__wrapper {
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media only screen and (max-width: 450px) {
  }

  @media handheld, only screen and (max-width: 767px) {
    #handheld-detection {
      display: none;
    }
  }

  .content-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  //layout
  .error();
  .layout();
  .header();
  .footer();
}
